* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}


.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.element {
  /*display: none;*/
  position: fixed;
  font-family: "Roboto Slab", Arial, sans-serif;
  /*font-weight: bold;*/
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  /*background-color: rgba(0, 0, 0, 0.8);*/
  color: #eeeeee;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  user-select: none;
  transition: opacity 0.5s ease-in-out;
}

.element.visible {
  /*display: block;*/
  opacity: 1;
}

.element.hidden {
  opacity: 0;
  pointer-events: none;
}

#loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#loading-text-wrapper {
  position: absolute;
  top: calc(50% - 250px);
  /* Adjust the value as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  text-align: center;
  width: 100%;
  /* Adjust the width as needed */
  max-width: 1000px;
  /* Adjust the maximum width as needed */
  margin-left: auto;
  margin-right: auto;
}

#loading-text-above {
  color: #fff;
  font-family: "Roboto Slab", Arial, sans-serif;
  /*font-weight: bold;*/
  font-size: 50px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {

  /* Adjust the maximum width as needed */
  #loading-text-wrapper {
    top: calc(50% - 375px);
  }
  #loading-bar-container {
    top: calc(50% - 50px);
  }
}
@media screen and (max-height: 721px) {

  /* Adjust the maximum width as needed */
  #loading-text-wrapper {
    top: calc(50% - 320px);
  }
  #loading-text-above {
    color: #fff;
    font-family: "Roboto Slab", Arial, sans-serif;
    /*font-weight: bold;*/
    font-size: 30px;
    margin-bottom: 10px;
  }
  #loading-bar-container {
    top: calc(50% - 50px);
  }
}


#loading-text-below {
  color: #fff;
  font-family: "Roboto Slab", Arial, sans-serif;
  /*font-weight: bold;*/
  font-size: 30px;
}

#loading-bar-container {
  width: 300px;
  height: 50px;
  background-color: #a1bde7;
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #fff;
  transition: width 0.3s ease;
}

#loading-text {
  color: #000000;
  font-family: "Roboto Slab", Arial, sans-serif;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
  display: inline-block;
  position: relative;
}

#start-button {
  width: 300px;
  height: 50px;
  padding: 0;
  background-color: rgb(255, 255, 255);
  color: #000;
  font-family: "Roboto Slab", Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  line-height: 50px;

  transition: background-color 0.3s ease;
}

#start-button:hover {
  background-color: #638fc9;
}

.link-button {
  width: 200px;
  height: 40px;
  margin-top: 10px;
  padding: 0;
  background-color: rgb(255, 255, 255);
  color: #000;
  font-family: "Roboto Slab", Arial, sans-serif;
  font-size: 20px;
  text-shadow: none;
  /*font-weight: bold;*/
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Set flex-direction to column */
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  transition: background-color 0.3s ease;
  text-decoration: none;
  user-select: none;
}
.link-button.disabled {
  color: gray;
  pointer-events: none;
  text-decoration: none;
  cursor: default;
}
.link-button:hover {
  background-color: #4a68b9;
  color: #fff;
}
.round-button {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  line-height: 50px;
  margin-right: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
}

.round-button:hover {
  background-color: #fff;
  color: #000;
  transform: scale(1.1); /* Increase size by 10% */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Add a box shadow for a subtle effect */
}

.linkedin-button {
  background-color: #0077B5;
}

.instagram-button {
  background-color: #E4405F;
}

.twitter-button {
  background-color: #1DA1F2;
}

.itch-button {
  background-color: #FA5C5C;
}

@keyframes pulse {
  0% {
    transform: translateX(-10px);
    opacity: 0.5;
  }

  50% {
    transform: translateX(10px);
    opacity: 1;
  }

  100% {
    transform: translateX(-10px);
    opacity: 0.5;
  }
}

#loading-dots {
  display: inline-block;
  position: relative;
  top: 10px;
  margin-left: 110px;

}

.dot {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  margin-left: 4px;
  display: inline-block;
  animation: pulse 1s infinite;
}

.dot:first-child {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:last-child {
  animation-delay: 0.4s;
}



/**************/
.hamburger {
  position: absolute;
  top: 5em;
  right: 0%;
  margin-left: -2em;
  margin-top: -45px;
  width: 70px;
  height: 70px;
  z-index: 5;
}

.hamburger div {
  position: relative;
  width: 3em;
  height: 7px;
  border-radius: 3px;
  background-color: #f8982b;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 200px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.97);
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
}

.menu {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  width: 100%;
}

.menu li {
  margin-bottom: 30px;
}

.menu a {
  display: block;
  font-size: 28px;
  font-family: "Roboto Slab", Arial, sans-serif;
  /*font-weight: bold;*/
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  padding: 10px 0;
  width: 100%;

  text-align: left;
  margin-left: 40px;
}
.menu a span{
  width: 100%;
  height: 100%; 
}
.menu a:hover {
  color: #f8982b;
}

#toggle:checked+.hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}

#toggle:checked+.hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}

#toggle:checked+.hamburger .meat {
  transform: rotate(45deg);
  margin-top: -7px;
}

#toggle:checked+.hamburger~.sidebar {
  right: 0;
}

/**********************/


.drag-to-explore {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 50px auto 0;
  border-radius: 50px;
  overflow: hidden;
  /*cursor: grab;*/
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  
}

.hand {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  animation: dragHand 2s infinite;
}

.hand i {
  font-size: 48px;
  color: #ccc;
}

.motion-circle {
  width: 50px;
  height: 50px;
  background-color: #222222a8;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
  opacity: 0;
  z-index: -1;
  animation: motionCircle 2s infinite;
}

.text {
  position: absolute;
  color: #fff;
  /*top: calc(50% + 30px);*/
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Roboto Slab", Arial, sans-serif;
  font-size: 28px;
  white-space: nowrap;
  pointer-events: none;
}

@keyframes dragHand {
  0% {
    right: 10px;
  }
  50% {
    right: calc(100% - 60px);
  }
  100% {
    right: 10px;
  }
}

@keyframes motionCircle {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.drag-to-explore.disabled {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}
/*************/


#triggerBoids_element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#triggerBoids_element .boids-text {
  font-size: 28px;
  font-family: "Roboto Slab", Arial, sans-serif;
  margin-bottom: 10px;
}

#triggerBoids_element .boids-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#triggerBoids_element .boids-button {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  background-color: rgb(255, 255, 255);
  color: #000;
  font-family: "Roboto Slab", Arial, sans-serif;
  font-size: 40px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

#triggerBoids_element .boids-button:hover {
  background-color: #4a68b9;
  color: #fff;
}

#triggerBoids_element #boidCount {
  font-size: 28px;
  font-family: "Roboto Slab", Arial, sans-serif;
  color: #ffffff;
  margin-top: 0px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#triggerBoids_element .button-text {
  font-size: 34px;
  margin-top: -4px;
}
